<template>
  <div class="page__wrapper">
    <div
      v-if="svgMap.length > 0"
      class="container-static"
    >
      <div
        v-for="(itemMap, index) in svgMap"
        :key="`map_${index}`"
        :id="`map_${index}`"
        class="map__wrapper"
      >
        <div
          v-html="itemMap.svg_map"
          :class="[scaleMapClass(index), 'map__container' ]"
        />
        <div class="btn-map__wrapper">
          <button
            @click="scaleMap(index, 50)"
            :class="styleClassBtnPlus(index)"
          >
            +
          </button>
          <span>{{ svgMapWidth[index] }}%</span>
          <button
            @click="scaleMap(index, -50)"
            :class="styleClassBtnMinus(index)"
          >
            -
          </button>
        </div>
        <div
          v-if="standInfo[index]"
          class="participant-info__wrapper"
        >
          <div class="participant-info">
            <button
              @click="closeStandInfo()"
              class="close-btn"
            >
              <i class="exu-x-close" />
            </button>
            <div class="participant-logo">
              <img
                :alt="`${standInfo[index].name}-logo`"
                :src="standInfo[index].logo"
              >
            </div>
            <div class="participant-about">
              <span class="participant-about-stand">Стенд {{ standInfo[index].stand }}</span>
              <span class="participant-about-company">{{ standInfo[index].name }}</span>
              <router-link
                :to="{ name: 'partnerProfile', params: { id: standInfo[index].id } }"
                class="participant-link"
              >
              Подробнее <i class="exu-chevron-right" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import appBaseNav from '@/components/mixins/appBaseNav';
import methodsmap from '@/components/mixins/apiMethodsMap';
import methodsSponsors from '@/components/mixins/methodsSponsors';

export default {
  name: 'MapPageView',
  mixins: [appBaseNav, methodsmap, methodsSponsors],
  props: {
    stand_id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      titleMap: null,
      svgMap: [],
      svgMapWidth: [],

      standsList: [],
      standInfo: [],

      fontsList: new Set(),
    };
  },
  mounted() {
    this.$store.commit('setPageTitle', 'Схема');
    document.fonts.forEach((font) => this.fontsList.add(font.family));
    this.loadPage();
  },
  methods: {
    loadPage() {
      this.getMap()
        .then((data) => {
          if (data && data.length) {
            this.svgMap = [...data];
            for (let i = 0; i < this.svgMap.length; i += 1) {
              this.svgMapWidth.push(100);
              this.standInfo.push(null);
              if (data[i].font_link && !this.fontsList.has(data[i].font_family)) {
                this.updateFonts(data[i].font_link);
                this.fontsList.add(data[i].font_family);
              }
            }
          }
        })
        .then(() => {
          document.querySelectorAll('svg#Map, svg.map').forEach((itemMap, indexMap) => {
            itemMap.querySelectorAll('#stands > g, .stands > g').forEach((stand) => {
              this.standsList.push(stand);
              if (this.stand_id === stand.id.replace('stand-', '')) {
                this.svgMapWidth[indexMap] = 200;
                this.standHandle(stand, indexMap);
              }
              stand.addEventListener('click', () => {
                this.standHandle(stand, indexMap);
              });
            });
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateFonts(fontLink) {
      const link = document.createElement('link');
      link.setAttribute('rel', 'stylesheet');
      link.setAttribute('type', 'text/css');
      link.setAttribute('href', fontLink);
      document.head.appendChild(link);
    },
    scaleMapClass(ind) {
      return `scale-map-${this.svgMapWidth[ind]}`;
    },
    scaleMap(ind, updWidth) {
      if (updWidth > 0) {
        if (this.svgMapWidth[ind] >= 200) this.svgMapWidth[ind] = 200;
        else this.svgMapWidth[ind] += +updWidth;
      } else if (this.svgMapWidth[ind] <= 100) this.svgMapWidth[ind] = 100;
      else this.svgMapWidth[ind] += +updWidth;
    },
    standHandle(stand, indMap) {
      this.closeStandInfo();
      this.standsList.forEach((itemStand) => {
        itemStand.classList.remove('active');
      });

      const standID = stand.id.replace('stand-', '');
      this.getSponsorByStand(standID)
        .then((data) => {
          if (data) {
            stand.classList.remove('stand-empty');
            stand.classList.add('active');
            this.standInfo[indMap] = {
              stand: standID,
              ...data,
            };
            stand.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
            });
          } else {
            stand.classList.add('stand-empty');
            this.standInfo[indMap] = null;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    closeStandInfo() {
      this.standsList.forEach((itemStand) => {
        itemStand.classList.remove('active');
      });
      this.standInfo = this.standInfo.map(() => null);
    },
    styleClassBtnMinus(ind) {
      if (this.svgMapWidth[ind] === 100) {
        return 'btn-map disabled';
      }
      return 'btn-map';
    },
    styleClassBtnPlus(ind) {
      if (this.svgMapWidth[ind] === 200) {
        return 'btn-map disabled';
      }
      return 'btn-map';
    },
  },
};
</script>
<style lang="scss">
.map {
  &__wrapper {
    display: grid;
    grid-template-columns: [startLine] 1fr [endLine];
    grid-template-rows: [startLine] auto [topSeparateLine] 1fr [bottomSeparateLine] auto [endLine];

    height: calc(var(--vh, 1vh) * 100 - 168px);
    padding: 15px;
    background: #fefefe;
    border-radius: 15px;
  }
  &__container {
    grid-column: startLine / endLine;
    grid-row: startLine / endLine;
    align-self: center;
    width: 100%;
    max-height: 100%;
    overflow: auto;

    &.scale-map-100 {
      svg {
        height: auto;
        width: 100%;
      }
    }
    &.scale-map-125 {
      svg {
        width: 125%;
      }
    }
    &.scale-map-150 {
      svg {
        width: 150%;
        padding-bottom: 100px;
      }
    }
    &.scale-map-175 {
      svg {
        width: 175%;
        padding-bottom: 100px;
      }
    }
    &.scale-map-200 {
      svg {
        width: 200%;
        padding-bottom: 100px;
      }
    }

    svg {
      transition-property: width;
      transition-duration: .75s;
    }
  }

  .stand {
    cursor: pointer;
  }
}
.participant {
  &-info {
    &__wrapper {
      position: sticky;
      bottom: 10px;
      grid-column: startLine / endLine;
      grid-row: bottomSeparateLine / endLine;
    }
    position: relative;
    display: flex;
    gap: 5px;
    color: #356899;
    background: #fefefe;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.25);
    padding: 4px;
    border-radius: 5px;

    .close-btn {
      position: absolute;
      top: 2px;
      right: 2px;
      width: 16px;
      height: 16px;
      font-size: 16px;
      border: none;
      background: transparent;
      padding: 0;
      color: #AFB0B6;
    }
  }
  &-logo {
    flex: 0 0 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    & > img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  &-about {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    &-stand {
      font-size: 1rem;
      font-weight: 600;
    }
    &-company {
      font-size: 1rem;
      line-height: 1.2em;
      font-weight: 400;
    }
  }
  &-link {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: .5rem;
    font-size: .9rem;
    color: #356899;
    text-decoration: none;

    & i {
      font-size: 1.1em;
    }
  }

  @media (min-width: 360px) {
    &-info {
      &__wrapper {
        gap: 15px;
      }
    }
    &-logo {
      @media (min-width: 360px) {
        flex: 0 0 100px;
      }
    }
  }
}
.btn-map {
  &__wrapper {
    z-index: 100;
    grid-column: startLine / endLine;
    grid-row: startLine / topSeparateLine;
    justify-self: end;

    display: flex;
    width: auto;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    // justify-content: space-between;

    font-size: .8rem;
    color: #356899;
    background: rgba(254,254,254,.9);
    padding: .5rem;
    border-radius: 5px;
  }

  &.disabled {
    color: #AFB0B6;
    border-color: #AFB0B6;
  }

  flex: 0 0 auto;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
  color: #356899;
  background: #fefefe;
  border: 2px solid #356899;
  border-radius: 5px;
}
</style>
