<!-- eslint-disable vuejs-accessibility/anchor-has-content -->
<template>
  <div class="profile__wrapper">
    <div
      v-if="partnerLogo"
      class="profile-content__wrapper"
    >
      <div class="profile-content full-size">
        <partner-card
          class="profile-card"
          :photo="partnerLogo"
        />
      </div>
    </div>
    <div
      v-if="isPartnerContacts"
      class="profile-content__wrapper"
    >
      <div class="profile-content full-size">
        <div
          class="hot-link-panel"
          :class="{'scroll-panel': partnerContactsLength > 3}"
          >
          <div class="row justify-content-evenly">
            <div
              v-if="partnerStand"
              :class="{'col': partnerContactsLength < 4, 'col-4': partnerContactsLength > 3}"
            >
              <up-card>
                <router-link
                  v-if="isLinkToMap"
                  :to="{ name: 'mapView', params: { stand_id: partnerStand } }"
                  class="map-link"
                >
                  <div>
                    <i class="exu-map" />
                  </div>
                  <div>
                    <span>Стенд</span>
                    <strong>{{ partnerStand }}</strong>
                  </div>
                </router-link>
                <div
                  v-else
                  class="map-link"
                >
                  <div>
                    <span>Стенд</span>
                    <strong>{{ partnerStand }}</strong>
                  </div>
                </div>
              </up-card>
            </div>
            <div
              v-for="(contactType, ind) in partnerContactsTile"
              :key="`tile-${ind}`"
              :class="{'col': partnerContactsLength < 4, 'col-4': partnerContactsLength > 3}"
            >
              <up-card
                @click.stop="togglePopup = [ true, contactType ]"
              >
                <div class="hot-link">
                  <i
                    v-if="contactType === 'site'"
                    class="exu-global"
                  />
                  <i
                    v-else-if="contactType === 'phone'"
                    class="exu-phone-1"
                  />
                  <i
                    v-else-if="contactType === 'email'"
                    class="exu-at-sign"
                  />
                  <i
                    v-else-if="contactType === 'messenger'"
                    class="exu-message-chat-square"
                  />
                </div>
              </up-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="partnerDescription"
      class="profile-content__wrapper"
    >
      <div class="profile-content">
        <h3>
          {{ $t('partnerProfile.about_company_title') }}
        </h3>
        <div
          v-if="isHtmlFormat"
          class="html-description-styles"
          v-html="partnerDescription"
        />
        <p
          v-else
          class="description-paragraph"
        >
          {{ partnerDescription }}
        </p>
      </div>
    </div>
    <popup-card
      v-if="showPopup"
      :is-open="showPopup"
      @show="(state) => { this.togglePopup = [state]; }"
    >
      <div>
        <ul class="contact-list">
          <li
            v-for="item in getContactType"
            :key="item.id"
          >
            <i :class="{
              'exu-global': item.type === 'site',
              'exu-phone-1': item.type === 'work' || item.type === 'mobile',
              'exu-at-sign': item.type === 'email',
              'exu-telegram-icon': item.type === 'telegram',
              'exu-whatsapp-icon': item.type === 'whatsapp',
            }"/>
            <div class="contact-detail">
              <p
                v-if="item.title"
                class="contact-title"
              >
                {{ item.title }}
              </p>
              <a
                v-if="item.tile === 'site'"
                :href="`http://${item.value}`"
                target="_blank"
              >
                {{ item.text || item.value }}
              </a>
              <a
                v-if="item.tile === 'phone'"
                :href="`tel:${item.value}`"
              >
                {{ item.text || item.value }}
              </a>
              <a
                v-if="item.tile === 'email'"
                :href="`mailto:${item.value}`"
              >
                {{ item.text || item.value }}
              </a>
              <a v-if="item.tile === 'messenger'"
                :href="item.value"
              >
                {{ item.text || item.value }}
              </a>
            </div>
        </li>
        </ul>
      </div>
    </popup-card>
  </div>
</template>

<script>
import appBaseNav from '@/components/mixins/appBaseNav';
import sponsors from '@/components/mixins/methodsSponsors';

export default {
  name: 'PartnersProfile',
  mixins: [appBaseNav, sponsors],
  props: {
    id: {
      type: String,
      requared: true,
    },
    locale: {
      type: String,
    },
  },
  data() {
    return {
      partner: null,
      showPopup: false,
      showType: null,

      isLoading: true,
      isLinkToMap: false,
    };
  },
  computed: {
    togglePopup: {
      get() {
        return this.showPopup;
      },
      set([newState, setType = null]) {
        this.showPopup = newState;
        if (setType === 'work' || setType === 'mobile') this.showType = 'phone';
        else if (setType === 'telegram' || setType === 'whatsapp') this.showType = 'messenger';
        else this.showType = setType;
      },
    },
    partnerStand() {
      return this.partner?.stand || null;
    },
    partnerLogo() {
      return this.partner?.logo || null;
    },
    isPartnerContacts() {
      return this.partner?.contacts && Array.isArray(this.partner.contacts);
    },
    partnerContacts() {
      return this.partner?.contacts.map((item) => {
        let blockType;
        switch (item.type) {
          case 'work':
          case 'mobile':
          case 'fax':
            blockType = 'phone';
            break;
          case 'telegram':
          case 'whatsapp':
            blockType = 'messenger';
            break;
          default:
            blockType = item.type;
            break;
        }
        return {
          ...item, tile: blockType,
        };
      }) || [];
    },
    partnerContactsTile() {
      return this.partnerContacts.reduce((acc, item) => {
        if (acc.includes(item.tile)) {
          return acc;
        }
        return [...acc, item.tile];
      }, []);
    },
    partnerContactsLength() {
      // return this.partner?.contacts.length || 0;
      return this.partnerContactsTile.length || 0;
    },
    partnerDescription() {
      return this.partner?.description || null;
    },
    getContactType() {
      switch (this.showType) {
        case 'site':
          return this.partnerContacts
            .filter((item) => item.tile === 'site')
            .map((item) => ({ ...item, value: item.value.replace(/https:\/\/|http:\/\//g, '') }));
        default:
          return this.partnerContacts.filter((item) => item.tile === this.showType);
      }
    },
    isTextFormat() {
      return this.partner.description_format === 'text';
    },
    isHtmlFormat() {
      return this.partner.description_format === 'html';
    },
  },
  watch: {
    locale() {
      this.loadPage();
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    loadPage() {
      this.$store.commit('setPageTitle', this.$t('partnerProfile.header_title'));
      this.getSponsorPersone(this.id)
        .then((sponsor) => {
          this.partner = { ...sponsor };
          this.isLoading = false;

          return this.partner.stand ? this.getSponsorByStand(this.partner.stand) : null;
        })
        .then((stand) => {
          if (stand !== null) this.isLinkToMap = true;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.profile__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  min-height: 100%;
  padding-top: 16px;
  padding-bottom: 24px;
}
.profile-content__wrapper {
  display: flex;
  align-items: center;
}
.profile-content {
  display: flex;
  padding: 0px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  &.full-size {
    width: 100%;
  }

  .description-paragraph {
    color: #95969D;
    // font-family: Roboto;
    // font-size: 14px;
    text-align: justify;
    margin: 0;
  }

  .logo-item > img {
    max-height: 44px;
  }

  .hot-link-panel {
    width: 100%;
    color: #356899;

    &.scroll-panel {
      overflow-x: scroll;

      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;               /* width of the entire scrollbar */
      }
      &:focus::-webkit-scrollbar {
        width: 4px;
        height: 4px;               /* width of the entire scrollbar */
      }

      &::-webkit-scrollbar-track {
        background: rgba(53,104,153,0);        /* color of the tracking area */
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(26,51,76,0);    /* color of the scroll thumb */
        border-radius: 2px;       /* roundness of the scroll thumb */
        border: none;  /* creates padding around scroll thumb */
      }
      &:focus::-webkit-scrollbar-track {
        background: rgba(53,104,153,.25);        /* color of the tracking area */
      }

      &:focus::-webkit-scrollbar-thumb {
        background-color: rgba(26,51,76,.25);    /* color of the scroll thumb */
        border-radius: 2px;       /* roundness of the scroll thumb */
        border: none;  /* creates padding around scroll thumb */
      }
      .row {
        flex-wrap: nowrap;
        padding-left: calc(var(--bs-gutter-x) * .25);
        padding-right: calc(var(--bs-gutter-x) * .25);

        &>* {
          width: 30%;
          padding-left: calc(var(--bs-gutter-x) * .25);
          padding-right: calc(var(--bs-gutter-x) * .25);
        }
      }
    }

    & .upCard {
      border: 2px solid #356899;
    }

    i {
      font-size: 24px;
    }
  }

  .map-link {
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 0;
    text-decoration: none;
    color: inherit;
    & > div {
      display: flex;
      height: 100%;
      flex-direction: column;
      gap: 5px;
      align-items: start;
      justify-content: center;

      & span {
        font-size: .8rem;
        line-height: 1em;
      }
      & strong {
        font-size: 1.5rem;
        line-height: 1em;
      }
    }
  }
  .hot-link {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 10px;
    padding: 12px 4px;

    @media(max-width: 450px) {
      & {
        padding: 12px 0;
      }
    }

    .hot-link-text {
      @media(max-width: 450px) {
        & {
          display: none;
        }
      }

      display: block;
      font-size: 14px;
      line-height: 1.15em;
      color: #95969D;
      text-decoration: none;
      text-align: center;
    }
  }

  h3 {
    font-family: Roboto;
    font-weight: 700;
    font-size: 20px;
  }

  .stretch-link {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}
.contact-list {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  gap: .4rem;
  list-style: none;

  & > li {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 16px;
    padding: 4px 0;
    font-size: 16px;
    line-height: 16px;
    color: #95969D;

    & > [class^="exu-"], [class*=" exu-"] {
      font-size: 1.5rem;
    }

    & > .contact-detail {
      display: flex;
      flex-direction: column;
      gap: .2rem;
      & > p {
        margin: 0;
      }
      & > a {
        color: #356899;
      }
    }
  }
}
</style>
