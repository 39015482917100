<template>
  <div class="expoup__wrapper">
    <main-header
      :main-title="mainTitle"
      :sub-title="subTitle"
      :isOpen="showAside"
      @aside-click="showingAside"
    />
    <div class="progress-bar__wrapper">
      <progress-bar />
    </div>
    <div
      id="content__wrapper"
      class="content__wrapper"
    >
      <pre-loader v-if="loading"/>
      <router-view
        v-if="isRouterView"
        :locale="locale"
        :launch-download="trunkOnDownload"
        :updatePwaExist="updatePWAExist"
        @update-config="updateConfig"
        @update-pwa="updatePWA(true)"
        @turn-off="eventTrunkDownload"
        @loading="showingPreloader"
        @show-banner="showingBanner"
        @switch-locale="switchLocale"
      />
    </div>
    <system-notification />
    <bottom-nav v-if="showBottomNav" />
    <aside-menu
      v-if="showAside"
      :locale="locale"
      :updatePwaExist="updatePWAExist"
      @switch-locale="switchLocale"
      @update-pwa="updatePWA(true)"
    />
    <div
      v-if="launchScreen"
      class="launch-screen"
    >
      <img
        src="/img/entrance.jpg"
        alt=""
      />
    </div>
    <ad-banner
      v-if="adBannerShowing"
      :banner="adBanner"
      @update-banner="adBannerShowing = false"
    />
  </div>
</template>

<script>
// import { DateTime } from 'luxon';
import global from '@/components/mixins/methodsGlobal';
import userMethods from '@/components/mixins/methodsUser';
import methodsEventsList from '@/components/mixins/methodsEventsList';
import updatePwa from '@/components/mixins/methodsPwaUpdate';
import timeCombaine from '@/components/mixins/timeCombaine';
import asideMenu from '@/components/shared/asideMenu/indexAsideMenu.vue';
import adBanner from '@/components/shared/adBannerView.vue';
import progressBar from '@/components/shared/progressBar.vue';
import systemNotification from '@/components/shared/systemNotification/index.vue';
// import bottomPwaUpdate from '@/components/shared/updPWA.vue';

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: {
    progressBar, asideMenu, adBanner, systemNotification,
  },
  data() {
    return {
      expoData: {},

      showedLauncScreen: false,
      timeoutLS: null,

      // showBanner: false,
      timerBanner: 0,
      timeoutShowBanner: null,
      timeoutCheckBanner: null,

      adBanner: {},
      adBannerShowing: false,

      showAside: false,
      mainContent: null,
      appLoaded: false,
      trunkOnDownload: false,
      loadingInbox: false,
      loading: false,
    };
  },
  mixins: [global, userMethods, methodsEventsList, updatePwa, timeCombaine],
  created() {
  },
  computed: {
    isRouterView() {
      return this.appLoaded && (this.$store.getters.getExhId || this.$route.name === 'EventsListView');
    },
    showBottomNav() {
      return this.$store.getters.isVisiblBottomNav;
    },
    mainTitle() {
      return (this.showAside || !this.$store.getters.getPageTitle)
        ? this.$store.getters.getMainTitle : this.$store.getters.getPageTitle;
    },
    dateSubtitle() {
      return this.testDateSubtitle(this.expoData.start_ts, this.expoData.end_ts);
    },
    subTitle() {
      return (this.showAside || this.isDashboard) && this.expoData.start_ts && this.expoData.end_ts ? this.dateSubtitle : '';
    },
    contentOffsetHeight() {
      return this.mainContent.offsetHeight;
    },
    launchScreen() {
      if (!this.showedLauncScreen) {
        return this.expoData.launchScreen || null;
      }
      return null;
    },
    isDashboard() {
      return this.$route.name === 'DashboardView';
    },
    locale: {
      get() {
        return this.$i18n.locale;
      },
      set(locale) {
        localStorage.setItem('__exup__locale', locale);
        this.$i18n.locale = locale;
      },
    },
    showBanner: {
      get() {
        return this.expoData.bannerSrc || null;
      },
      set(src) {
        this.expoData.bannerSrc = src;
      },
    },
  },
  watch: {
    $route() {
      this.showAside = false;
    },
  },
  beforeMount() {
    this.screenResize();
    window.addEventListener('resize', this.screenResize);

    this.checkToken();
    this.declinedUpdTs = localStorage.getItem('__cancel_update__');

    if (!this.showedLauncScreen) {
      this.timeoutLS = setTimeout(() => {
        this.showedLauncScreen = true;
      }, 2000);
    }

    this.getEventsList()
      .then((data) => {
        let aproveID = null;
        if (data) {
          // eslint-disable-next-line max-len
          aproveID = data.results.find((eventItem) => eventItem.id === this.$store.getters.getExhId && eventItem.is_public);
        }
        if (aproveID) {
          this.updateConfig();
        } else {
          this.$store.commit('setExhId', null);
          localStorage.removeItem('exhID');
          this.$router.push({ name: 'home' });
          this.appLoaded = true;
        }
      });
  },
  mounted() {
    this.mainContent = document.getElementById('content__wrapper');
    this.mainContent.addEventListener('scroll', this.handleScrollToBottom);
  },
  methods: {
    updateConfig() {
      this.appLoaded = false;

      const exhID = this.$store.getters.getExhId;
      const jsonCachedConfig = localStorage.getItem(`exhID-${exhID}-config`);

      if (jsonCachedConfig) {
        const cachedConfig = JSON.parse(jsonCachedConfig);
        this.applyConfig(cachedConfig);
      } else {
        this.$store.commit('setMainTitle', { lang: 'ru', text: '...' });
        this.$store.commit('setMainTitle', { lang: 'en', text: '...' });
      }

      if (this.$store.getters.getToken && !this.$store.getUserID) {
        this.getUserProfile()
          .then((data) => {
            this.$store.commit('setUserID', data.id || null);
          });
      }

      // Promise.all([
      //   this.getConfig(exhID),
      //   this.checkInbox(),
      // ])
      Promise.resolve(this.initExhibition(exhID))
        .then(([
          config,
          { notification, chat },
          // inbox,
        ]) => {
          // console.log('3', inbox);
          // const { notification, chat } = inbox;
          this.applyConfig(config);

          if (notification || chat) {
            this.recalcNotification({ notification, chat });
          }
        })
        .finally(() => {
          this.appLoaded = true;
        })
        .catch((error) => {
          console.error(error);
          console.log('5');
        });
    },
    // перенести в computed
    showingPreloader(state) {
      this.loading = state;
    },
    showingAside() {
      this.showAside = !this.showAside;
      this.checkInbox()
        .then(({ notification, chat }) => {
          if (notification || chat) {
            this.recalcNotification({ notification, chat });
          }
        })
        .catch(() => {});
    },
    switchLocale(val) {
      this.showAside = false;
      this.locale = val;
      this.$store.commit('setLang', val);
      // this.$router.go();
    },
    showingBanner({ src, link }) {
      this.adBanner = {
        src,
        link,
        timer: 5,
      };
      this.adBannerShowing = true;
    },
    recalcNotification({ notification = 0, chat = 0 }) {
      this.$store.commit('setNewNotificationCount', notification);
      this.$store.commit('setUnreadChatCount', chat);
    },
    eventTrunkDownload({ stateDownload, stateCheckInbox }) {
      if (stateDownload !== undefined) {
        this.trunkOnDownload = stateDownload;
      }
      if (stateCheckInbox !== undefined) {
        this.loadingInbox = stateCheckInbox;
      }
    },
    screenResize() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    handleScrollToBottom() {
      requestAnimationFrame(this.redrawLayer);
    },
    redrawLayer() {
      if (this.mainContent.children.length) {
        const ofsetHeight = this.mainContent.children[0].offsetHeight;
        if (ofsetHeight - this.mainContent.scrollTop < 800) {
          this.trunkOnDownload = true;

          // TODO вынести в отдельный метод проверки состояния
          if (!this.loadingInbox) {
            this.loadingInbox = true;
            this.checkInbox()
              .then(({ notification, chat }) => {
                this.recalcNotification({ notification, chat });
              })
              .catch(() => {
                this.loadingInbox = false;
              });
          }
        }
      } else {
        this.handleScrollToBottom();
      }
    },
  },
  // eslint-disable-next-line vue/no-deprecated-destroyed-lifecycle
  beforeDestroy() {
    window.removeEventListener('resize', this.screenResize);
    this.mainContent.removeEventListener('scroll', this.handleScrollToBottom);
  },
};
</script>

<style lang="scss">
@import url('@/assets/style/fonts.scss');
body {
  margin: 0;
  padding: 0;
  background: #303030;
  & * {
    box-sizing: border-box;
  }
}
.expoup__wrapper {
  display: grid;
  width: 100%;
  // max-width: 760px;
  margin: 0 auto;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  grid-template-columns:
    [header-start content-start aside-start bottom-nav-start] 1fr
    [header-end content-end bottom-nav-end aside-end];
  grid-template-rows:
    [header-start] 76px
    [header-end progress-start] auto
    [progress-end content-start aside-start] 1fr
    [system-start] auto
    [content-end bottom-nav-start] auto [bottom-nav-end aside-end];
  background: #F6F6FA;
  font-size: 16px;
  font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
}
.progress-bar__wrapper {
  grid-column-start: content-start;
  grid-column-end: content-end;
  grid-row-start: progress-start;
  grid-row-end: progress-end;
}
.content__wrapper {
  grid-column-start: content-start;
  grid-column-end: content-end;
  grid-row-start: content-start;
  grid-row-end: content-end;
  overflow: hidden auto;
  z-index: 10;

  &.full-content {
    grid-column-start: content-start;
    grid-column-end: content-end;
    grid-row-start: aside-start;
    grid-row-end: aside-end;
    z-index: 180;
  }
}
.bottom-update__wrapper, .systemNotification__wrapper {
  grid-column-start: content-start;
  grid-column-end: content-end;
  grid-row-start: system-start;
  grid-row-end: bottom-nav-end;
  z-index: 190;
}
.bottom-nav__wrapper {
  grid-area: bottom-nav;
  z-index: 160;
  background: #FEFEFE;
}

aside {
  grid-area: aside;
  display: flex;
  justify-content: end;
  width: 100%;
  background: rgba(0, 0, 0, .5);
  transition: opacity .5s;
  z-index: 200;
}
.launch-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  grid-column-start: content-start;
  grid-column-end: content-end;
  grid-row-start: header-start;
  grid-row-end: aside-end;
  z-index: 1100;
  background: #F6F6FA;

  & > img {
    // max-width: 100%;
    max-height: 100%;
  }
}
.container-static {
  width: 100%;
  max-width: 760px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin: 0 auto;
  padding: 16px;
}
.inner-html {
  & p {
    margin-bottom: 8px;
  }
  & ul {
    padding: 16px;
    margin: 0;
  }
  a {
    color: #356899;
    text-decoration: none;
  }
  :last-child {
    margin-bottom: 0;
  }
}
// ===============
.overflow-unset {
  overflow: unset;
}
.html-description-styles {
  li {
    &::marker {
      color: #356899;
    }
  }
  .attention {
    li {
      &::marker {
        color: #D76535;
      }
    }
  }
}
</style>
